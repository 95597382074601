mat-form-field {
  &.templating-input {
    .mat-form-field-flex {
      background-color: #ffffff;
      border: 2px solid #EBEBEB;
      border-radius: 0.25em;
      padding: 0em 0.75em;
    }
    .mat-form-field-underline {
      display: none;
    }
    .mat-form-field-wrapper {
      padding: 0;
    }
    .mat-form-field-infix {
      padding: 0.25em 0 0.5em 0;
      border-top-width: 0.25em;
    }

    mat-select {
      .mat-select-trigger .mat-select-arrow-wrapper {
        transform: none;
      }
    }
  }
}

.dynamic-field-text-name-form .mat-form-field {
  width: 315px;
  margin-top: 15px;
}