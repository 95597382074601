$enl-primary: #ED5F42;

mat-tab-group {
  .mat-tab-label {
    opacity: 1;
    height: 2em;
    padding: 0;
    margin: 0;
    .mat-tab-label-content {
      font-weight: bold;
      color: $enl-primary;
    }
  }
}

mat-tab-header {
  &.mat-tab-header {
    border-bottom: none;
  }
}

mat-ink-bar {
  &.mat-ink-bar {
    background-color: $enl-primary !important;
  }
}
