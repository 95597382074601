mat-checkbox.template-select-panel-cb {
  label.mat-checkbox-layout {
    max-width: 16.5vw;
  }

  span.mat-checkbox-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}