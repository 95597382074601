/*
  All these styles using the participant-dialog-phone-input class are used to make the
  ngx-intl-tel-input component look like a Material UI input.

  This is necessary in libs/ui/dialogs/src/lib/modules/markup-room-participant-dialog/markup-room-participant-dialog.component.html
  so this input fits with the rest of the form.
*/
.participant-dialog-phone-input .iti.iti--allow-dropdown {
  width: 100%;
}

.participant-dialog-phone-input .iti__flag-container {
  padding-bottom: 9px;
}

.participant-dialog-phone-input .iti.iti--allow-dropdown input {
  background-color: inherit;
  height: 22px;
  width: 100%;
  outline: none;
  padding-bottom: 9px;
  border-bottom: 1px solid rgb(143,166,176);
}

.participant-dialog-phone-input .iti.iti--allow-dropdown input:focus {
  border-bottom: 2px solid rgba(2, 58, 81, 0.87);
}

.participant-dialog-phone-input .iti.iti--allow-dropdown input:hover {
  border-bottom: 2px solid rgba(2, 58, 81, 0.87);
}

.participant-dialog-phone-input .phone-error ngx-intl-tel-input .iti.iti--allow-dropdown input {
  border-bottom: 2px solid red;
}

.participant-dialog-phone-input .iti.iti--allow-dropdown .dropdown-menu {
  width: 300px;
  // TODO: Remove this when other countries are enabled. There's no other way to hide the dropdown by the component itself...
  display: none;
}