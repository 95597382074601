@use "../../../node_modules/@ag-grid-community/styles" as ag;
@tailwind base;
@tailwind components;
@tailwind utilities;

@include ag.grid-styles((
  theme: alpine
));

.ag-theme-alpine {
  /* customise with CSS variables */
  --ag-grid-size: 8px;
  --ag-font-size: 15px;
  --ag-header-background-color: #FFFFFF;
  --ag-odd-row-background-color: #FFFFFF;
  --ag-font-family: Cera, sans-serif;
  --ag-checkbox-checked-color: #2196f3;
}

.ag-theme-alpine .ag-header {
  /* or with CSS selectors targeting grid DOM elements */
  font-style: italic;
}

.ag-header-cell-text {
  font-style: normal;
}

.mat-app-background {
  background-color: transparent;
}

.ag-root-wrapper {
  border-radius: 8px;
  box-shadow: 0 0 3px rgb(0 0 0 / 12%), 0 0 3px rgb(0 0 0 / 24%);
  border-color: transparent;
}

.mat-checkbox-inner-container .mat-checkbox-inner-container-no-side-margin {
  color: #2196f3 !important;
}